import { useTranslation } from "react-i18next";
import { Background, DownloadBtn, TextBox, TextBoxPhone, Title, Wrapper } from "./style";
import { useSelector } from "react-redux";
import { selectLanguage } from "redux/reducers/languageReducer";

const Com4 = () => {
  const language = useSelector(selectLanguage);
  const { t } = useTranslation("languageNewVer");

  const handlerNavToEbitmall = () => {
    window.open("https://www.ebitmall.net/", "_blank");
  };

  return (
    <Wrapper>
      <Title>
        <p>{t("S_500120")}</p>
        <p>{t("S_500121")}</p>
      </Title>
      <TextBoxPhone>
        <p>{t("S_500130")}</p>
        <div>{t("S_500140")}</div>
      </TextBoxPhone>
      <Background lang={language === "en"}>
        <div>
          <TextBox lang={language === "en"}>{t("S_500130")}</TextBox>
          <DownloadBtn onClick={handlerNavToEbitmall}>{t("S_500140")}</DownloadBtn>
        </div>
      </Background>
    </Wrapper>
  );
};

export default Com4;
